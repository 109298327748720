<template>
  <div>
    <div class="outer-wrapper">
      <div class="container-fluid">
          <div class="row justify-content-center mob-row">
            <div class="col-sm-12 col-lg-8 left-container">
              <div class="col-12">
                  <div class="compass text-right mobile">
                    <img src="@/assets/images/compass.png" alt="" width="42" height="46">
                  </div>
                </div>
              <div class="col-sm-12  unit-plan-tab-section d-flex px-0">
                <div class="col-lg-6 logo-header px-0">
                  <div class="brand-logo-mob text-left">
                    <img src="@/assets/images/nr-logo.png" width="100px" height="100px" alt="" class="img-fluid">
                  </div>
                </div>             
              </div>
              <div class="plan-holder">
                <div class="text-center font-weight-bold mb-2 mt-2">Shubham Artesia - {{ selectedTypology.wing }} Wing</div>
                <svg class="mx-auto" v-if="units && units.length > 0" id="floor_map" :width="units[0].width" :height="units[0].height" :viewBox="`0 0 ${units[0].width} ${units[0].height}`" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <image class="pointer-none" id="image0_4701_200" :width="units[0].width" :height="units[0].height" :href="`${units[0].floorPlan}`" />
                  <g v-for="(flat, flatKey) in this.flats">
                    <g v-for="(path, pathKey) in units[0].paths" v-if="flat.variant_id == path.id">
                      <path stroke="#0499B7" fill="#0499B7" stroke-width="4" :key="`${pathKey}-${flatKey}`"  :id="path.id" 
                        v-if="flat.status === 'Available'"
                        :d="path.path" @click="selectUnit(flat)"
                        :opacity="isPathSelected(path, path) ? selectedOpacity : path.opacity"
                        :fill-opacity="isPathSelected(path, path) ? selectedFillOpacity : isPathHovered(path) ? hoverFillOpacity : path.fillOpacity" 
                        @mouseover="highlightWing(path);" @mouseleave="removehighlight(path)" 
                      />
                      <path v-else stroke="#000000" :key="`${flatKey}-${pathKey}`" stroke-width="4" :id="path.id" :d="path.path" fill="#000000" opacity="0.75" fill-opacity="0.6" />
                      <b-popover v-if="flat.status === 'Blocked'" :show="true" triggers="" :target="path.id" placement="top" custom-class="popover-wrapper-white">
                        <template>
                          <div class="text-left mb-1"><span>Flat No</span> {{ flat.name }}</div>
                          <div class="text-left ">{{ flat.blocking.client_names || flat.blocking.user_names }} {{ flat.blocking.is_tenant ? '(Exst Member)' : ' (Owner)' }}</div>
                        </template>
                      </b-popover>
                      <b-popover v-if="flat.status === 'Booked'" :show="true" triggers="" :target="path.id" placement="top" custom-class="popover-wrapper-white">
                        <template>
                          <div class="text-left mb-1"><span>Flat No</span> {{ flat.name }}</div>
                          <div class="text-left ">{{ flat.booking.client_names }} &nbsp;(Sale)</div>
                        </template>
                      </b-popover>
                      <b-popover triggers="click hover blur" v-if="flat.status === 'Available' && !isPathSelected(path, path)" :target="path.id" placement="top" custom-class="popover-wrapper"> {{ flat.name }}</b-popover>
                      <b-popover v-if="flat.status === 'Available' && showMySelectedPopup && isPathSelected(path, path)" :show="true" triggers="" :target="path.id" placement="top" custom-class="popover-wrapper">{{ flat.name }}</b-popover>
                    </g>
                  </g>
                </svg>
                <!-- <div class="compass text-right">
                  <img src="@/assets/images/compass.png" alt="" width="84" height="92">
                </div> -->
              </div>
            </div>
            <div class="col-sm-12 col-lg-4 right-container">
              <UnitForm/>
            </div>
          </div>
           <div class="col-12 powered-by powered-by-mob">Powered by <span class="powered-by-ppistol"> Enrichr</span></div>
        <!-- <ArialView />
        <VirtualVisit /> -->

      </div>

    </div>
  </div>

</template>
<script>

import {FormSteps, masterJson} from '@/assets/scripts/utility.js';


export default {
  components: {
    FloorPlanSVG:()=>import("@/components/FloorPlanSVG.vue"),
    UnitForm: ()=>import("@/components/Home/UnitForm.vue"),
    ProgressBar: ()=>import('@/components/ProgressBar.vue')
  },
  data(){
    return{
      isHovered: false,
      showhighlight: true,
      selectedOpacity: 0.8,
      selectedFillOpacity: 0.2,
      hoverData: {},
      hoverOpacity: 0.8,
      hoverFillOpacity: 0.2,
      formSteps:FormSteps,
      flats: [],
      selectedFlat: null,
      showMySelectedPopup: false
    }
  },
  mounted(){
    this.getFlats();
    this.$store.dispatch('setLoading',false);
    if (this.selectedTypology && this.selectedTypology.id) {
      setTimeout(() => this.showMySelectedPopup = true, 300);
    }
  },
  computed: {
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedUnit() {
      return this.$store.getters.selectedUnit;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    units(){
      return masterJson.units.filter(u => u.building == this.selectedTypology.wing && u.floor == this.selectedFlat?.floor)
    },
  },
  watch: {
    selectedUnit() {
      this.showMySelectedPopup = false;
      setTimeout(() => this.showMySelectedPopup = true, 300);
    }
  },
  methods: {
    getFlats(){
      let project_name = 'SHUBHAM ARTESIA (VASHI)'
      let fromPath = `/inventories/flats`;
      this.$axios.get(fromPath, {
        params: {
          "project_name": `${project_name}`,
          "building_id": this.selectedFloor.building_id,
          "floor": this.selectedFloor.floor,
          "name": this.selectedFloor.name
        }
      }).then(res => {
        if(res.data){
          this.flats = res.data.flat_details;
          this.selectedFlat = res.data.selected_flat;
          this.$store.dispatch('setUnit', res.data.selected_flat);
        }
      })
    },
    selectUnit(flat){
      if(flat.id !== this.selectedFlat.id){
        let fromPath = `/inventories/flats`;
        this.$axios.get(fromPath, {
          params: {
            "building_id": this.selectedFloor.building_id,
            "floor": this.selectedFloor.floor,
            "name": flat.name
          }
        }).then(res => {
          if(res.data){
            this.selectedFlat = res.data.selected_flat;
            this.$store.dispatch('setUnit', res.data.selected_flat);
          }
        })
      }
    },
    handleUnitSelection(unit){
      if(unit.status_id === 1){
        this.$store.dispatch('setUnit', unit);
      }
    },
    toggleTab(id,btnId){
      $('#planTabs .tab-pane').removeClass('active');
      $(`${id}`).addClass('active');
      $('.nav-pills .unit-button').removeClass('active');
      $(btnId).addClass('active');

    },
    highlightWing(row) {
      row.opacity = this.selectedOpacity;
      row.fillOpacity = this.selectedFillOpacity;
      this.showhighlight = false
      this.showhighlight = true;
      this.hoverData = row;
    },
    removehighlight(row) {
      row.opacity = 0.01;
      row.fillOpacity = 0.02;
      this.showhighlight = false
      this.showhighlight = true
    },
    isPathSelected(path) {
      return this.selectedUnit && this.selectedUnit.variant_id === path.id;
    },
    isPathHovered(path){
      return this.hoverData && this.hoverData.id === path.id;
    }
  }
};
</script>
<style scoped>

.unit-details-wrapper{
  width: 100%;
  height: 100%;
  min-height: 60vh;
}

.pointer-none{
  pointer-events: none;
}

svg path{
  cursor: pointer;
  outline: none !important;
}

.floor-item{
  border: 1px solid rgba(4, 153, 183, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  max-width: 596px;
  margin: 16px auto;
  position: relative;
  z-index: 1;
}

.floor-item .floor-title{
  position: absolute;
  top: 16px;
  left: 32px;
  font-size: 16px;
  font-weight: 600;
  z-index: 6;
}

.floor-item button{
    padding: 68px 16px 0px;
    border: none;
    border-bottom: 4px solid transparent;
    background-color: #fff;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s;
}

.floor-item button .plan-icon{
  color: #CACACA;
  outline: none;
}

.floor-item button.Booked .plan-icon, .floor-item button.Booked span, 
.floor-item button.Blocked .plan-icon, .floor-item button.Blocked span{
  color: #DB2424;
}

.floor-item button.Available .plan-icon, .floor-item button.Available span{
  color: #77BF1A;
}

.floor-item button.filtered{
  background-color: rgba(119,191,26,0.1);
}

.floor-item button.active{
  border-bottom: 4px solid #0499B7;
  background-color: rgba(4, 153, 183, 0.1) !important;
}

.floor-item button.active .plan-icon, .floor-item button.active span{
  color: #0499B7;
}

.floor-item button:hover{
  background-color: #efefef;
}

.unit-title{
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  padding: 16px 0;

}

.unit-title span{
  color: rgba(87, 147, 10, 1);
  font-weight: 500;
  display: inline-block;
}

.refuge .unit-title span, .Blocked .unit-title span{
  color: rgba(0, 0, 0, 0.5);
}

.refuge{
  pointer-events: none !important;
}

.Booked .info-icon, .Blocked .info-icon{
  position: absolute;
  right: 22px;
  top: 104px;
  pointer-events: none
}

.logo-header {
  display: flex;
  position: absolute;
  z-index: 2;
}

#floor_map{
  width: 100%;
  height: 100%;
  max-width: max-content;
  max-height: max-content;
}
.mob-show{
  display: none;
}
.outer-wrapper {
  margin: 0;
  background: #f6f6f6;

}

.right-container{
  background: #ffffff;
  padding: 0px 30px;
  position: relative;
  z-index: 2;
}

.title-section{
  display: flex;
}

.unit-plans-buttons{
  display: flex;
  margin-left: auto;
  align-items: flex-end;
}

.plan-holder {
  border-radius: 10px;
  padding: 0;
  background: transparent;
  padding-bottom: 20px;
}

.tab-content .tab-pane{
  overflow-x: auto;
}

.plan-holder-img{
  max-height: 516px;
}

.compass-img{
  margin-left: auto;
  margin-top: auto;
}

.compass-img-mob{
  display: none;
}

.badge-config{
  background-color: #cacaca;
  color: #fff !important;
  display: inline-block;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 8px;
}

button.Available .badge-config{
  background-color: #77BF1A;
}

button.Booked .badge-config, button.Blocked .badge-config{
  background-color: #DB2424;
}

button.active .badge-config{
  background-color: #0499B7;
}

@media (max-width:1100px){
  .plan-holder {
    margin: 0 auto;
    width: fit-content;
  }

}




@media (min-width:992px){

  #floor_map{
    transform: scale(0.9)
  }

  .left-container{
    height: 100vh;
    height: 100dvh;
    overflow-y: scroll;
  }

  .unit-plan-tab-section{
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .plan-holder{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
    min-height: 100vh;
    padding-top: 10px;
  }
  .unit-progress-bar{
    position: absolute;
    bottom: 5px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}


.side-menu {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  padding: 20px;
  margin: revert;
}

.side-menu.typology {
  height: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 15px 15px 15px 40px;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 17px;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 20px;
  left: 18px;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.prevButton {
  color: #082D57;
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}
.form-wrapper {
  padding: 0 20px;
}
.form-wrapper  label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}
.form-wrapper  select {
  background: url(../assets/images/caret-icon.svg) no-repeat right .75rem center #FFFFFF;
  border: 1px solid #E7EBF0;
  border-radius: 3px;
  padding: 10px;
  appearance: none;
  height: auto;
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}
.sub-head {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
  display: block;
}
.card-foot {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
  }
  .card-footer-head span{
    letter-spacing: -0.01em;
    color: #082D57;
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;
  }
  h5 {
    font-weight: 600;
    font-size: 15px;
    color: #082D57;
  }
.link-btn {
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}

.unit-button,
.nav-pills .nav-link{
  background: #AB3E9180;
  color: #ffffff80;
  padding: 8px;
}

.nav-pills .nav-item:first-child .nav-link{
  border-radius: 4px 0px 0px 4px;
}

.nav-pills .nav-item:last-child .nav-link{
  border-radius: 0px 4px 4px 0px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #AB3E91;
  color: #ffffff;
}

.powered-by-mob{
  display: none;
}

.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  margin-top: 20px;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

.info-wrapper{
    margin-bottom: 40px;
    background: #fff;
    padding-right: 15px;
    border-radius: 10px;
    display: flex;
 }

  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 14px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: none;
    opacity: 0.5;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }
@media (min-width: 992px){
  .right-container{
    overflow-y: auto;
    height: 100vh;
  }
}

@media (max-width:991px){

  .unit-plan-tab-section{
    position: static;
    margin-top: 80px;
    z-index: 2;
  }

  .mob-hide{
    display: none;
  }

  .row.mob-show{
    display: flex;
  }
  .info-wrapper.mob-show{
    padding:104px 20px 30px 5px;
    background: #FFFFFF;
    border: 1px solid rgba(4, 153, 183, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }
  .right-container{
    margin-top: 20px;
    position: static;
    padding: 0;
  }
  .logo-header{
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  }

  .logo-header h3{
    margin: auto;
  }

  .brand-logo-mob{
    position: absolute;
  }

  .powered-by{
    display: none;
  }

  .powered-by.powered-by-mob{
    display: block;
    position: static;
    margin: auto;
    text-align: center;
    transform: none;
    margin-top: 40px;
    margin-bottom: 160px;
  }
  .side-menu{
    min-height: unset;
  }
  .unit-plans-buttons{
    justify-content: center;
  }
  .plan-holder-img{
    width: 100%;
  }
  .plan-holder .tab-content{
    margin-bottom: 20px !important;
  }
  .compass-img{
    display: none;
  }
  .compass-img-mob{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

}

@media (max-width: 768px){

  .badge-config{
    font-size: 10px;
  }


  .floor-item button .plan-icon{
   width: 64px;
   height: 36px; 
  }

  .floor-item button .info-icon{
    width: 16px;
    height: 16px;
    right: 30%;
    top: 98px;
  }

  .floor-item .floor-title{
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
  }
}

@media (max-width: 460px){

  .floor-item button{
    padding: 68px 10px 0px;
  }

  .floor-item button .info-icon{
    right: 24%;
  }
  .floor-item button .plan-icon{
    width:56px;
  }
}
</style>